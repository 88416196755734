import { api } from '@/assets/boot/axios'
import routes from '@/router/index'
import Swal from 'sweetalert2'

async function apiGet(path) {
  let data
  await api
    .get(`${path}`)
    .then((response) => {
      data = response.data
    })
    .catch((error) => {
      alertError(error, path)
    })
  return data
}


async function apiPost(path, payload, headers) {
  let data
  if (headers === undefined) {
    await api
      .post(`${path}`, payload)
      .then((response) => {
        data = response
      })
      .catch((error) => {
        console.log("error in api")
        alertError(error, path)
      })
    return data
  } else {
    await api
      .post(`${path}`, payload, headers)
      .then((response) => {
        data = response
      })
      .catch((error) => {
        alertError(error, path)
      })
    return data
  }
}


function alertError(error, path) {
  console.log('error.code', error.code)
  console.log('error', error)
  if (error.code === 'ERR_NETWORK') {
    console.log('Microservices catch:', error)
    Swal.fire({
      title: `Internal server error.\n Die Microservices sind nicht erreichbar. \n Bitte versuchen Sie es später erneut. \n `,
      buttonsStyling: false,
      confirmButtonClass: 'md-button md-success',
    })
  } else if (error.code === 'ERR_BAD_REQUEST') {
    if (error.response.status === 401) {
      console.log('401 catch:', error)
      Swal.fire({
        title: `Session abgelaufen.\n Bitte melden Sie sich erneut an`,
        buttonsStyling: false,
        confirmButtonClass: 'md-button md-success',
      })
      routes.push('/auth/login')
      // alertError("Bitte melden Sie sich erneut an")
    }
  } else if (error.code === 'ERR_BAD_RESPONSE') {
    if (error.response.status === 500) {
      console.log('500 catch:', error)
      Swal.fire({
        title: `Internal server error.\n Bitte versuchen Sie es später erneut. \n `,
        buttonsStyling: false,
        confirmButtonClass: 'md-button md-success',
      })
    }
  }
}

export { apiGet, apiPost }
